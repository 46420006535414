import React, { Component } from "react";

import UserListForm from "./userListFront";
import DelFunc from "./userListFunc";

import { FirebaseContext } from "../../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import * as ROUTES from "../../../constants/routes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Helpers from "../../Helpers";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";

const INTIAL_STATE = {
  columns: [],
  options: {},
  clientList: [],
  alertOpen: false,
  navps: [],
  exportData: [],
  adminStatusOptions: {},
};

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INTIAL_STATE };
    this.db = firebase.firestore();
    this.deleteUserFunction = new DelFunc();
    this.Helpers = new Helpers();
  }

  componentDidMount() {
    var filter = 0;

    if (this.props.history.location.state) {
      if (this.props.history.location.state.from === "DB") {
        filter = this.props.history.location.state.status;
      }
    }

    const columns = [
      {
        name: "date",
        label: "Submission Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === 9999999999
                  ? "-"
                  : moment.unix(value).format("MM/DD/YYYY h:mm a")}
              </>
            );
          },
        },
      },
      {
        name: "registrationDate",
        label: "Creation Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{moment.unix(value).format("MM/DD/YYYY h:mm a")}</>;
          },
        },
      },
      {
        name: "accountNumber",
        label: "Account Number",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "clientName",
        label: "Client Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "portfolioValue",
        label: "Portfolio Value",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === 0 ? "-" : this.Helpers.formatToPHP(value)}</>;
          },
        },
      },
      {
        name: "status",
        label: "Application Status",
        options: {
          filter: true,
          sort: true,
          filterList:
            filter === 1 || filter === 2
              ? ["Pending Approval", "Returned"]
              : [],
        },
      },
      {
        name: "tier",
        label: "Tier",
        options: {
          filter: true,
          sort: true,
          filterList: filter === 1 ? [1] : filter === 2 ? [2] : [],
        },
      },
      {
        name: "daysPending",
        label: "Days Pending Approval",
        options: {
          filter: false,
          sort: true,
          // filterOptions: {
          //   names: [], // Hide the filter options in the dropdown
          // },
          // filterList: filter === 2 ? [1, 2, 3, 4, 5, 6, 7] : [],
          customBodyRender: (value) => {
            return (
              <>
                {value === "-"
                  ? "-"
                  : value > 1
                  ? `${value} Days`
                  : `${value} Day`}
              </>
            );
          },
        },
      },
      {
        name: "adminStatus",
        label: "Admin Status",
        options: {
          filter: true,
          sort: true,
          filterList:
            filter === "pending"
              ? ["LONG_KYC-7", "LONG_KYC-6"]
              : filter === 2
              ? [
                  "Started Long KYC",
                  "Submitted Long KYC",
                  "Returned Long KYC",
                  "Declined Long KYC",
                  "Approved Long KYC / Fully Verified User",
                  "Investment Cap Reached",
                  "",
                ]
              : [],
          filterOptions: {
            names: [
              "Investment Cap Reached",
              "Started Long KYC",
              "Submitted Long KYC",
              "Returned Long KYC",
              "Declined Long KYC",
              "Pending Face-to-Face Verification",
              "Pending BDO Attestation",
              "Approved Long KYC / Fully Verified User",
              "Started Short KYC",
              "Submitted Short KYC",
              "Returned Short KYC",
              "Declined Short KYC",
              "Approved Short KYC / Short KYC User",
              "Landing Page / Referrer Page",
              "Email Verified",
            ],
            logic: (adminStatus, filters) => {
              const statusMapping = {
                LIMIT: "Investment Cap Reached",
                "Started Long KYC": "Started Long KYC",
                "LONG_KYC-2": "Submitted Long KYC",
                "LONG_KYC-3": "Returned Long KYC",
                "Declined Long KYC": "Declined Long KYC",
                "LONG_KYC-6": "Pending Face-to-Face Verification",
                "LONG_KYC-7": "Pending BDO Attestation",
                "Approved Long KYC / Fully Verified User":
                  "Approved Long KYC / Fully Verified User",
                "Started Short KYC": "Started Short KYC",
                "Submitted Short KYC": "Submitted Short KYC",
                "Returned Short KYC": "Returned Short KYC",
                "Declined Short KYC": "Declined Short KYC",
                "Approved Short KYC / Short KYC User":
                  "Approved Short KYC / Short KYC User",
                "Landing Page / Referrer Page": "Landing Page / Referrer Page",
                "Email Verified": "Email Verified",
              };

              const mappedFilters = filters.map((filter) => {
                const mappedValue = Object.keys(statusMapping).find(
                  (key) => statusMapping[key] === filter
                );
                return mappedValue || filter;
              });

              return !mappedFilters.includes(adminStatus);
            },
          },

          customBodyRender: (value, tableMeta) => {
            const rowData = tableMeta.rowData;

            if (!value) return "";

            if (
              [
                "LIMIT",
                "LONG_KYC-2",
                "LONG_KYC-3",
                "LONG_KYC-5",
                "LONG_KYC-6",
                "LONG_KYC-7",
              ].includes(value)
            ) {
              return (
                <FormControl>
                  <Select
                    value={value}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.updateStatus(e.target.value, rowData[4]);
                    }}
                    onClose={(e) => e.stopPropagation()}
                  >
                    {[value, "LONG_KYC-6", "LONG_KYC-7"]
                      .filter(
                        (item, index, arr) => index === 0 || item !== arr[0]
                      )
                      .map((item, index) =>
                        index === 0 ? (
                          <MenuItem value={value}>
                            {this.state.adminStatusOptions[value]}
                          </MenuItem>
                        ) : (
                          <MenuItem value={item}>
                            {this.state.adminStatusOptions[item]}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              );
            } else {
              return this.state.adminStatusOptions[value];
            }
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      responsive: "standard",
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      selectableRows: false,
      download: false,
      print: false,
      rowsPerPage: 100,
      sortOrder: {
        name: "date",
        direction: "asc",
      },
      onRowClick: (data, meta) => {
        this.redirectUser(meta.dataIndex);
      },
    };
    this.setState({ columns, options });
    this.getCurrentNavps();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  getCurrentNavps() {
    let navps = {};

    let arrPromise = [];

    arrPromise.push(
      this.db
        .collection("fundRates")
        .orderBy("dateUploaded", "desc")
        .limit(1)
        .get()
    );

    arrPromise.push(this.db.collection("funds").get());

    Promise.all(arrPromise)
      .then((result) => {
        if (result[0].size < 1) throw new Error("NAVPS not found");

        if (result[1].size < 1) throw new Error("Funds not found");

        navps = result[0].docs[0].data().fundDetails;
        result[1].forEach((doc) => {
          let navpsIndex = navps.findIndex(
            (x) => x.fundName === doc.data().name
          );
          navps[navpsIndex].fundCode = doc.data().code;
        });

        this.setState({ navps });
        this.getUserList();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }


  async updateStatus(status, email,) {
    try {
      const user = this.state.clientList.find((x) => x.email === email);
      if (!user) throw new Error('missing user');

      const token = this.Helpers.getCookie("token");
      await axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "metrics-metrics/assign-status",
          JSON.stringify({
            uid: user.userId,
            status
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
      await this.getUserList();
    } catch (err) {
      console.log(err);
      alert('Admin Status: ', err.message);
    }
  }

  async getUserList() {
    const users = [],
      exportData = [];

    const referrersSnapshot = await this.db.collection("referrers").get();
    const statusRef = await this.db.collection("miscellaneous").doc('constants').get();
    const statusConstants = statusRef.data().status;

    const referrers = [];
    referrersSnapshot.forEach((doc) =>
      referrers.push({ id: doc.id, ...doc.data() })
    );

    this.db
      .collection("users")
      .where("role", "==", "client")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const myDetails = doc.data();
          let status = this.getStatus(myDetails);
          const daysPending = this.getDaysPending(myDetails, status);

          if (daysPending !== "-") {
            if (daysPending > 7 && myDetails.accountNumber.length < 8) {
              status = "Abandoned";
            }
          }

          let date = myDetails.dateKYCCompleted?.seconds || 9999999999;

          let hasKYC;
          if (myDetails.hasLongKYC) hasKYC = myDetails.hasLongKYC;
          else if (myDetails.hasShortKYC) hasKYC = myDetails.hasShortKYC;
          else hasKYC = false;

          const tier = myDetails.stats?.tier
            ? myDetails.stats?.tier
            : "Legacy Tier";

          const referrer =
            myDetails?.referrerId && myDetails?.referrerId?.length > 0
              ? (() => {
                const obj = referrers.find(
                  (x) => x.id === myDetails.referrerId
                );
                return `${obj.firstName} ${obj.lastName}`;
              })()
              : "n/a";

          const adminStatus = myDetails.stats?.status ? myDetails.stats?.status : '';

          users.push({
            userId: doc.id,
            hasKYC,
            accountNumber:
              myDetails.accountNumber === "" ? "-" : myDetails.accountNumber,
            clientName: `${myDetails.firstName} ${myDetails.lastName}`,
            registrationDate: myDetails.dateCreated.seconds,
            date,
            portfolioValue: this.getPortfolioValue(myDetails),
            email: myDetails.email,
            manager:
              !myDetails.investmentManager ||
                myDetails.investmentManager.investmentManagerId === ""
                ? "N/A"
                : myDetails.investmentManager.investmentManagerName,
            status,
            daysPending,
            tier,
            referrer,
            adminStatus
          });

          exportData.push({
            submissionDate:
              date === 9999999999
                ? "-"
                : moment.unix(date).format("MM/DD/YYYY h:mm a"),
            registrationDate: moment
              .unix(myDetails.dateCreated.seconds)
              .format("MM/DD/YYYY h:mm a"),
            accountNumber: myDetails?.accountNumber,
            lastName: myDetails.lastName,
            firstName: myDetails.firstName,
            email: myDetails.email,
            portfolioValue: this.getPortfolioValue(myDetails),
            status: status,
            daysPending: daysPending,
            referrer,
          });
        });
        this.setState({ clientList: users, exportData, adminStatusOptions: statusConstants });
      })
      .catch((error) => {
        alert(error);
      });
  }

  redirectUser = (index) => {
    let clickedUser = this.state.clientList[index];

    if (clickedUser.hasKYC)
      this.props.history.push({
        pathname: ROUTES.CLIENT_PORFILE,
        state: { user: clickedUser.userId, from: "CL" },
      });
    else {
      this.setState({ alertOpen: true });
    }
  };

  getStatus = (data) => {
    let status = "Ongoing Application";
    if ("declinedStatus" in data) {
      if (data.declinedStatus === "returned") status = "Returned";
      else if (data.declinedStatus === "approved") status = "Approved";
      else status = "Declined";
    } else {
      if (data.dateKYCCompleted) {
        if (data.approvedLongKYC) status = "Approved";
        else status = "Pending Approval";
      } else {
        status = "Ongoing Application";
      }
    }

    const tier = data?.stats?.tier ?? 3;
    if (
      data?.stats?.tierUpgradeStatus &&
      data?.stats?.tierUpgradeStatus === "pending" &&
      tier === 2
    ) {
      status = "Pending Approval";
    }
    return status;
  };

  getDaysPending = (data, status) => {
    let daysPending = "-";

    if (!data.dateKYCCompleted) return daysPending;

    if (["Pending Approval", "Returned"].includes(status)) {
      daysPending = parseInt(
        (moment().unix() - data.dateKYCCompleted.seconds) / 86400
      );
    }

    return daysPending;
  };

  getPortfolioValue = (data) => {
    let moneyValue = 0;
    if ("investmentSummary" in data) {
      data.investmentSummary.forEach((item) => {
        const fundIndex = this.state.navps.findIndex(
          (x) => x.fundCode === item.fundCode
        );
        moneyValue += this.state.navps[fundIndex].rate * item.value;
      });
    }
    return moneyValue;
  };

  closeAlert = (event, reason) => {
    if (reason === "clickaway") return;

    this.setState({ alertOpen: false });
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <UserListForm
            state={this.state}
            firebase={firebase}
            closeAlert={() => this.closeAlert.bind(this)}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(UserPage);
